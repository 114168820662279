import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import AppHeader from "../../../components/pages/AppHeader";
import { useStateValue } from "../../../providers/StateProvider";
import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../../config/Constants";



import MenuIcon from '@mui/icons-material/Menu';
import order from '../../../assets/images/telemedicine/order.png'
import orderAct from '../../../assets/images/telemedicine/orderAct.png'
import payment from '../../../assets/images/telemedicine/payment.png'
import paymentAct from '../../../assets/images/telemedicine/paymentAct.png'
import processing from '../../../assets/images/telemedicine/processing.png'
import processingAct from '../../../assets/images/telemedicine/processingAct.png'
import historyIcon from '../../../assets/images/telemedicine/history.png'
import historyAct from '../../../assets/images/telemedicine/historyAct.png'
import logoBlue from '../../../assets/images/telemedicine/logoMain.png';
interface props {
    children?: React.ReactNode;
}

function AppLayout({ children }: props) {

    const [{ user }]: any = useStateValue();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const [_menuActive, _setMenuActive] = useState<string>('orders');
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isActive, setIsActive] = useState('false');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchterms, setSearchterms] = useState("");
    const changeTab = (tab: string) => {
        history.push(ROUTES.PHARMACY.HOME + tab);
    };
    
    let seachingdata = useHistory();
    useEffect(() => {
        const path = location.pathname;
        const lastPath = path.substring(path.lastIndexOf('/') + 1);

        if (lastPath === "Historypayment") {

            _setMenuActive("history");
        }
        else if (lastPath === "Processingstatus") {

            _setMenuActive("processing");
        }
        else {
            _setMenuActive(lastPath);
        }

    }, [location.pathname]);

    const changeseachvalue = (event: any) => {
        setSearchterms(event.target.value);
        localStorage.setItem("Masterserach", searchterms);
        // props.push({
        //     event.target.value;
        // })
        //window.location.assign;// .reload();
        children?.toString(event.target.value);


        // props.history.push({
        //     searchvalue:event.target.value
        // })
    }
    return (<>
        <div className={clsx('min-vh-100')}>
            {!isMobile &&
                <div className="d-flex justify-content-center side-menu-block">
                    <div className="side-menu-content">
                        <div className="header-section d-flex justify-content-center align-items-center">
                            <h3 className="mb-0 fw-bold text-white"></h3>
                            <span><img src={logoBlue} alt="" style={{ width: "9rem" }} className="my-3" /></span>
                        </div>
                        <div className="side-menu-list">
                            <ul className="nav nav-pills flex-column mb-auto" id="side-menu">
                                <NavLink to={ROUTES.PHARMACY.HOME + 'orders'} onClick={() => { setIsActive('order'); }} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "order" ? <img src={order} className="me-2 dashicon1" /> : <img src={orderAct} className="me-2 dashicon1" />}
                                    </div>
                                    <div className="flex-shrink-1">Order</div>
                                </NavLink>
                                <NavLink to={ROUTES.PHARMACY.HOME + 'processing'} onClick={() => setIsActive('processing')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "processing" ? <img src={processing} className="me-2 dashicon1" /> : <img src={processingAct} className="me-2 dashicon1" />}
                                    </div>
                                    <div className="flex-shrink-1">Processing</div>
                                </NavLink>
                                <NavLink to={ROUTES.PHARMACY.HOME + 'payments'} onClick={() => setIsActive('payment')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "payment" ? <img src={payment} className="me-2" style={{ width: "1rem" }} /> : <img src={paymentAct} className="me-2" style={{ width: "1rem" }} />}
                                    </div>
                                    <div className="flex-shrink-1">Payments</div>
                                </NavLink>

                                <NavLink to={ROUTES.PHARMACY.HOME + 'history'} onClick={() => setIsActive('history')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "history" ? <img src={historyIcon} className="me-2 dashicon1" /> : <img src={historyAct} className="me-2 dashicon1" />}
                                    </div>
                                    <div className="flex-shrink-1">History</div>
                                </NavLink>
                            </ul>
                        </div>
                    </div>
                </div>}
            <main className={clsx(!isMobile && "desktop", 'main-content')}>
                <div className='d-flex justify-content-between'>
                    {openDrawer === false &&
                        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                            {isMobile && <MenuIcon />}
                        </IconButton>}
                    {openDrawer &&
                        <div className="d-flex justify-content-center side-menu-block">
                            <div className="side-menu-content">
                                <div className="header-section d-flex justify-content-center align-items-center">
                                    <h3 className="mb-0 fw-bold text-white"></h3>
                                    <span><img src={logoBlue} alt="" style={{ width: "9rem" }} className="my-3" /></span>
                                </div>
                                <div className="side-menu-list">
                                    <ul className="nav nav-pills flex-column mb-auto" id="side-menu">
                                        <NavLink to={ROUTES.PHARMACY.HOME + 'orders'} onClick={() => { setIsActive('order'); }} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "order" ? <img src={order} className="me-2 dashicon1" /> : <img src={orderAct} className="me-2 dashicon1" />}
                                            </div>
                                            <div className="flex-shrink-1">Order</div>
                                        </NavLink>
                                        <NavLink to={ROUTES.PHARMACY.HOME + 'processing'} onClick={() => setIsActive('processing')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "processing" ? <img src={processing} className="me-2 dashicon1" /> : <img src={processingAct} className="me-2 dashicon1" />}
                                            </div>
                                            <div className="flex-shrink-1">Processing</div>
                                        </NavLink>
                                        <NavLink to={ROUTES.PHARMACY.HOME + 'payments'} onClick={() => setIsActive('payment')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "payment" ? <img src={payment} className="me-2" style={{ width: "1rem" }} /> : <img src={paymentAct} className="me-2" style={{ width: "1rem" }} />}
                                            </div>
                                            <div className="flex-shrink-1">Payments</div>
                                        </NavLink>

                                        <NavLink to={ROUTES.PHARMACY.HOME + 'history'} onClick={() => setIsActive('history')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "history" ? <img src={historyIcon} className="me-2 dashicon1" /> : <img src={historyAct} className="me-2 dashicon1" />}
                                            </div>
                                            <div className="flex-shrink-1">History</div>
                                        </NavLink>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='col-md-9'>
                        <Stack direction="row" spacing={5} className="align-items-center justify-content-evenly bg-white py-3 px-5">
                            <AppHeader loginName={user?.name} />
                        </Stack>

                    </div>
                </div>
                <div className={clsx('p-4 bg-secondary')}>
                    {children}
                </div>

            </main>

            {/* <AppHeader loginName={user?.name}/>
            <div className="my-3" >
                <div className="container" style={{width:"1250px"}}>
                <div className="row justify-content-between align-items-center flex-wrap w-100 position-relative g-0">
                        <div className="col-md-6 d-flex justify-content-start order-1 order-md-0">
                            <TabMenuItem menuName={"Order"} imgIcon={imgOrder}  imgIconSelected={imgOrderSelected} active={_menuActive === 'orders'} onClick={() => changeTab('orders')}/>
                            <TabMenuItem menuName={"Processing"} imgIcon={imgProcessing} imgIconSelected={imgProcessingSelected} active={_menuActive === 'processing'} onClick={() => changeTab('processing')}/>
                            <TabMenuItem  menuName={"Sugamah Payment"} imgIcon={imgRupee} imgIconSelected={imgRupeeSelected} active={_menuActive === 'payments'} onClick={() => changeTab('payments')} />
                            <TabMenuItem menuName={"History"} imgIcon={imgHistory} imgIconSelected={imgHistorySelected} active={_menuActive === 'history'} onClick={() => changeTab('history')}/>
                        </div>
                        <div hidden className="col-md-4 d-flex justify-content-end order-0 order-md-1 mb-3 mb-md-0 col-sm-12">
                            <Paper hidden sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '25rem'}} className={pageStyle.tabSearch}>
                                <InputBase sx={{ml: 1, flex: 1}} placeholder="Search" name="Search" id ="Search" onChange={changeseachvalue}/>
                                <IconButton type="submit" sx={{p: '10px'}} aria-label="search" name="btnSearch" id ="btnSearch">
                                    <IconSearch/>
                                </IconButton>
                            </Paper>
                        </div>
                    </div>
                    <div className={clsx(pageStyle.tabContent, 'p-4')}>
                        {children}
                    </div>
                </div>
            </div> */}
        </div>
    </>);
}

export default AppLayout;