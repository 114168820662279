import React, {useEffect, useState}   from 'react';
import clsx                           from "clsx";
import AppHeader                      from "../../../components/pages/AppHeader";
import {useStateValue}                from "../../../providers/StateProvider";
import TabMenuItem                    from "../../../components/pages/TabMenuItem";
import {IconButton, InputBase, Paper, Stack} from "@mui/material";
import pageStyle                      from "../../page.module.scss";
import {Search as IconSearch}         from "@mui/icons-material";
import {useHistory, useLocation}      from "react-router-dom";
import {ROUTES}                       from "../../../config/Constants";

import imgOrder           from "../../../assets/images/icon-order-1.png";
import imgOrderSelected   from "../../../assets/images/icon-order-3-selected.png";
import imgRupee           from "../../../assets/images/icon-rupee-1.png";
import imgRupeeSelected   from "../../../assets/images/icon-rupee-1-selected.png";
import imgFamily          from "../../../assets/images/icon-family-1.png";
import imgFamilySelected  from "../../../assets/images/icon-family-1-selected.png";
import imgHistory         from "../../../assets/images/icon-history-1.png";
import imgHistorySelected from "../../../assets/images/icon-history-3-selected.png";

interface props {
    children?: React.ReactNode;
}

function AppLayout({children}: props) {
    debugger;
    const [{user}]: any                 = useStateValue();
    const history                       = useHistory();
    const location                      = useLocation();
    const [_menuActive, _setMenuActive] = useState<string>('orders');

    const changeTab = (tab: string) => {
        history.push(ROUTES.AGENT.HOME + tab);
    };
    const [_disable,_setdisable]=useState(false);

    useEffect(() => {
        debugger;
        const path     = location.pathname;
        const lastPath = path.substring(path.lastIndexOf('/') + 1);
        _setMenuActive(lastPath);
        console.log("Agentcode-"+user?.showMainAgent);
        _setdisable(user?.showMainAgent);
    }, [location.pathname]);

    return (<>
        <div style={{backgroundColor: '#f6f7fb'}} className={clsx('min-vh-100')}>
        
            <div>
                <Stack direction="row" spacing={5} className="align-items-center justify-content-evenly bg-white py-3 px-5">
                    {/* <AppHeader loginName={user?.name} /> */}
                    <AppHeader loginName={user?.name} loginid={user?.id} logincode={user?.agentcode} chkMainAgent={user?.showMainAgent} subagentcode={user?.subagentcode} agenttype={user?.type}/>

                </Stack>

            </div>
            
            <div className="my-3">
                <div className="container" style={{width:"1250px"}}>
                    <div className="row justify-content-between align-items-center flex-wrap w-100 position-relative g-0">
                        <div className="col-md-4 d-flex justify-content-start order-1 order-md-0">
                            <TabMenuItem menuName={"Subscription"} imgIcon={imgOrder} imgIconSelected={imgOrderSelected} active={_menuActive === 'subscriptions'} onClick={() => changeTab('subscriptions')}/>
                            <TabMenuItem menuName={"Sugamah Payment"} imgIcon={imgRupee} imgIconSelected={imgRupeeSelected} active={_menuActive === 'payments'} onClick={() => changeTab('payments')}/>
                            {user.type === 'Agent'  && <TabMenuItem menuName={"My Team"} imgIcon={imgFamily}  imgIconSelected={imgFamilySelected} active={_menuActive === 'my-team'} onClick={() => changeTab('my-team')}/>}
                            <TabMenuItem menuName={"History"} imgIcon={imgHistory} imgIconSelected={imgHistorySelected}  active={_menuActive === 'history'} onClick={() => changeTab('history')}/>
                        </div>
                        <div  className="col-md-4 d-flex justify-content-end order-0 order-md-1 mb-3 mb-md-0">
                            <Paper hidden sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '25rem'}} className={pageStyle.tabSearch}>
                                <InputBase sx={{ml: 1, flex: 1}} placeholder="Search3"/>
                                <IconButton type="submit" sx={{p: '10px'}} aria-label="search">
                                    <IconSearch/>
                                </IconButton>
                            </Paper>
                        </div>
                    </div>
                    <div className={clsx(pageStyle.tabContent, 'p-4')}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AppLayout;