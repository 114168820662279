import React             from 'react';
import {useStateValue}   from "../providers/StateProvider";
import {Redirect, Route} from "react-router-dom";
import {ROUTES}          from "../config/Constants";

function PublicRoute({component: Component, restricted = true, ...rest}: any) {
    const [{user}]: any = useStateValue();
    return (
        <Route {...rest} render={props => (
            user && restricted ?
            <Redirect to={ROUTES.HOME}/>
                               : <Component {...props} />
        )}/>
    );
}

export default PublicRoute;