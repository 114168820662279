import React from 'react';
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../config/Constants";


interface props {
    children?: React.ReactNode;
}

function AuthLayout({ children }: props) {
    const location = useLocation();
    return (<>
        <div className=' d-flex justify-content-center bg-primary min-vh-100 backimg'>
            <div className="col-md-8">{children}</div>
        </div>
    </>);
}

export default AuthLayout;