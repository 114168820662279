import React, {lazy, Suspense} from 'react';
import AgentThemeProvider      from "../../providers/AgentThemeProvider";
import AuthLayout              from "./components/AuthLayout";
import PublicRoute             from "../../routes/PublicRoute";
import {ROUTES}                from "../../config/Constants";
import {Redirect, Switch}      from "react-router-dom";
import AppLayout               from "./components/AppLayout";
import {useStateValue}         from "../../providers/StateProvider";
import PrivateRoute            from "../../routes/PrivateRoute";
import paymenthistory from './components/PaymentHistory';

const Login           = lazy(() => import('./auth/Login'));
const Register        = lazy(() => import('./auth/Register'));
const ContactDetails  = lazy(() => import('./onboarding/ContactDetails'));
const Documents       = lazy(() => import('./onboarding/Documents'));
const Confirmation    = lazy(() => import('./onboarding/Confirmation'));
const TabSubscription = lazy(() => import('./components/TabSubscription'));
const TabMyTeam       = lazy(() => import('./components/TabMyTeam'));
const TabHistory      = lazy(() => import('./components/TabHistory'));
const TabPayments     = lazy(() => import('./components/TabPayments'));
const Paymenthistory  =lazy(()=> import('./components/PaymentHistory'));
const Agent           =lazy(()=> import('./components/Agent')); 
const Subscriptioncancel           =lazy(()=> import('./components/Subscriptioncancel')); 
const Subscriptionexpaired           =lazy(()=> import('./components/Subscriptionexpaired')); 
const Subscriptionrenewal           =lazy(()=> import('./components/Subscriptionrenewal')); 

const ContactDetailsSubAgent    = lazy(() => import('./../SubAgent/Onboarding/ContactDetails'));
const DocumentsSubAgent         = lazy(() => import('./../SubAgent/Onboarding/Documents'));
const ConfirmationSubAgent      = lazy(() => import('./../SubAgent/Onboarding/Confirmation'));

function Index() {
    const [{user}]: any = useStateValue();

    return (<div className="theme-agent">
        <AgentThemeProvider>
            <SetLayout component={user ? AppLayout : AuthLayout} user={user}/>
        </AgentThemeProvider>
    </div>);
}

function SetLayout({component: Component, user}: any) {
    return (
        <Component>
            <Suspense fallback={null}>
                <Switch>
                    <PublicRoute path={ROUTES.AGENT.LOGIN} component={Login}/>
                    <PublicRoute path={ROUTES.AGENT.REGISTER} component={Register}/>
                    <PublicRoute path={ROUTES.AGENT.ONBOARD_CONTACT_DETAILS} component={ContactDetails}/>
                    <PublicRoute path={ROUTES.AGENT.ONBOARD_DOCUMENT} component={Documents}/>
                    <PublicRoute path={ROUTES.AGENT.ONBOARD_CONFIRM} component={Confirmation}/>
                    <PrivateRoute path={ROUTES.AGENT.SUBSCRIPTIONS} component={TabSubscription}/>
                    <PrivateRoute path={ROUTES.AGENT.PAYMENT} component={TabPayments}/>
                    <PrivateRoute path={ROUTES.AGENT.MY_TEAM} component={TabMyTeam}/>
                    <PrivateRoute path={ROUTES.AGENT.HISTORY} component={TabHistory}/>
                    <Redirect from={ROUTES.AGENT.HOME} to={user ? ROUTES.AGENT.SUBSCRIPTIONS : ROUTES.AGENT.LOGIN} exact/>
                    {/* <Redirect from={ROUTES.AGENT.PAYMENTHISTORY} to={user ? ROUTES.AGENT.PAYMENTHISTORY : ROUTES.AGENT.LOGIN} exact/>  */}

                    <PrivateRoute path={ROUTES.AGENT.PAYMENTHISTORY} component={paymenthistory}/>
                    <PrivateRoute path={ROUTES.AGENT.AGENT} component={Agent}/>
                    <PrivateRoute path={ROUTES.AGENT.Subscriptioncancel} component={Subscriptioncancel}/>
                    <PrivateRoute path={ROUTES.AGENT.Subscriptionexpaired} component={Subscriptionexpaired}/>
                    <PrivateRoute path={ROUTES.AGENT.Subscriptionrenewal} component={Subscriptionrenewal}/>

                    <PublicRoute path={ROUTES.SUBAGENT.ONBOARD_CONTACT_DETAILS} component={ContactDetailsSubAgent}/>
                    <PublicRoute path={ROUTES.SUBAGENT.ONBOARD_DOCUMENT} component={DocumentsSubAgent}/>
                    <PublicRoute path={ROUTES.SUBAGENT.ONBOARD_CONFIRM} component={ConfirmationSubAgent}/>
                </Switch>
            </Suspense>
        </Component>
    );
}

export default Index;