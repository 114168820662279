import React, {Suspense} from 'react';
import {Switch}          from 'react-router-dom';
import PublicRoute       from "./PublicRoute";
import {ROUTES}          from "../config/Constants";
import Home              from '../pages/Home';
import Hospital          from '../pages/Hospital';
import Agent             from '../pages/Agent';
import Lab               from '../pages/Lab';
import Pharmacy          from '../pages/Pharmacy';
import PrivateRoute      from "./PrivateRoute";
import Logout            from "../pages/Home/Logout";


function RouteApp() {
    return (
        <Suspense fallback={<>loading</>}>
            <Switch>
                <PublicRoute path={ROUTES.HOSPITAL.HOME} component={Hospital} restricted={false}/>
                <PublicRoute path={ROUTES.AGENT.HOME} component={Agent} restricted={false}/>
                <PublicRoute path={ROUTES.LAB.HOME} component={Lab} restricted={false}/>
                <PublicRoute path={ROUTES.PHARMACY.HOME} component={Pharmacy} restricted={false}/>
                <PublicRoute path={ROUTES.HOME} component={Home} exact restricted={false}/>
                <PrivateRoute path={ROUTES.LOGOUT} component={Logout}/>
            </Switch>
        </Suspense>
    );
}

export default RouteApp;