import React                     from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import RouteApp                  from "./RouteApp";

function RouteInit() {
    return (
        <Router>
            <RouteApp/>
        </Router>
    );
}

export default RouteInit;