import React         from 'react';
import clsx          from "clsx";
import style         from "./../style.module.scss";
import {useLocation} from "react-router-dom";
import {ROUTES}      from "../../../config/Constants";

import imgAgent  from '../../../assets/images/agent-bg-1.png';
import imgAgent2 from '../../../assets/images/agent-bg-2.png';

interface props {
    children?: React.ReactNode;
}

function AuthLayout({children}: props) {
    const location = useLocation();
    return (<>
        <div className=' d-flex justify-content-center bg-primary min-vh-100 backimg'>
                {/* <div className={clsx('d-md-block', 'col-md-4', 'position-relative', style.BackgroundImage,
                                     location.pathname === ROUTES.AGENT.ONBOARD_CONFIRM && 'col-md-12',
                                     location.pathname !== ROUTES.AGENT.ONBOARD_CONFIRM && 'collapse')}
                     style={{backgroundImage: `url(${location.pathname === ROUTES.AGENT.ONBOARD_CONFIRM ? imgAgent2 : imgAgent})`}}>
                </div> */}
                {location.pathname === ROUTES.AGENT.ONBOARD_CONFIRM && <>
                    {children}
                </>}
                {location.pathname !== ROUTES.AGENT.ONBOARD_CONFIRM && <div className="col-md-8 p-3">{children}</div>}
        </div>
    </>);
}

export default AuthLayout;