import React, {useEffect, useState}   from 'react';
import clsx                           from "clsx";
import AppHeader                      from "../../../components/pages/AppHeader";
import {IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import {NavLink, useHistory, useLocation}      from "react-router-dom";
import {ROUTES}                       from "../../../config/Constants";
import {useStateValue}       from "../../../providers/StateProvider";



import MenuIcon from '@mui/icons-material/Menu';

import uploadImg from '../../../assets/images/telemedicine/upload.png';
import order from '../../../assets/images/telemedicine/order.png';
import orderAct from '../../../assets/images/telemedicine/orderAct.png';
import payment from '../../../assets/images/telemedicine/payment.png';
import paymentAct from '../../../assets/images/telemedicine/paymentAct.png';
import processing from '../../../assets/images/telemedicine/processing.png';
import processingAct from '../../../assets/images/telemedicine/processingAct.png';
import historyIcon from '../../../assets/images/telemedicine/history.png';
import historyAct from '../../../assets/images/telemedicine/historyAct.png';
import logoBlue from '../../../assets/images/telemedicine/logoMain.png';

interface props {
    children?: React.ReactNode;
}



function AppLayout({children}: props) {
    const [{user}]: any                 = useStateValue();
    const history                       = useHistory();
    const location                      = useLocation();
    const [_menuActive, _setMenuActive] = useState<string>('orders');
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isActive, setIsActive] = useState('false');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchterms, setSearchterms] = useState("");

    const changeTab = (tab: string) => {
        history.push(ROUTES.LAB.HOME + tab);
    };

    useEffect(() => {
        const path     = location.pathname;
        const lastPath = path.substring(path.lastIndexOf('/') + 1);
        if(lastPath==="UploadDocument")
       {
        _setMenuActive("Upload");
       }
        else{
        _setMenuActive(lastPath);
        }


       // _setMenuActive(lastPath);
    }, [location.pathname]);
const passvaluefromseach=(e:any)=>{
    children=e.target.value;
    
}

    return (<>
    <div className={clsx('min-vh-100')}>
            {!isMobile &&
                <div className="d-flex justify-content-center side-menu-block">
                    <div className="side-menu-content">
                        <div className="header-section d-flex justify-content-center align-items-center">
                            <h3 className="mb-0 fw-bold text-white"></h3>
                            <span><img src={logoBlue} alt="" style={{ width: "9rem" }} className="my-3" /></span>
                        </div>
                        <div className="side-menu-list">
                            <ul className="nav nav-pills flex-column mb-auto" id="side-menu">
                                <NavLink to={ROUTES.LAB.HOME + 'orders'} onClick={() => { setIsActive('order'); }} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "order" ? <img src={order} className="me-2 dashicon1" /> : <img src={orderAct} className="me-2 dashicon1" />}
                                    </div>
                                    <div className="flex-shrink-1">Order</div>
                                </NavLink>
                                <NavLink to={ROUTES.LAB.HOME + 'processing'} onClick={() => setIsActive('processing')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "processing" ? <img src={processing} className="me-2 dashicon1" /> : <img src={processingAct} className="me-2 dashicon1" />}
                                    </div>
                                    <div className="flex-shrink-1">Processing</div>
                                </NavLink>
                                <NavLink to={ROUTES.LAB.HOME + 'history'} onClick={() => setIsActive('history')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "history" ? <img src={historyIcon} className="me-2 dashicon1" /> : <img src={historyAct} className="me-2 dashicon1" />}
                                    </div>
                                    <div className="flex-shrink-1">History</div>
                                </NavLink>
                                <NavLink to={ROUTES.LAB.HOME + 'Upload'} onClick={() => setIsActive('upload')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                        {isActive === "upload" ? <img src={uploadImg} className="me-2" style={{ width: "1rem" }} /> : <img src={uploadImg} className="me-2" style={{ width: "1rem" }} />}
                                    </div>
                                    <div className="flex-shrink-1">Upload</div>
                                </NavLink>

                            </ul>
                        </div>
                    </div>
                </div>}
            <main className={clsx(!isMobile && "desktop", 'main-content')}>
                <div className='d-flex justify-content-between'>
                    {openDrawer === false &&
                        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                            {isMobile && <MenuIcon />}
                        </IconButton>}
                    {openDrawer &&
                        <div className="d-flex justify-content-center side-menu-block">
                            <div className="side-menu-content">
                                <div className="header-section d-flex justify-content-center align-items-center">
                                    <h3 className="mb-0 fw-bold text-white"></h3>
                                    <span><img src={logoBlue} alt="" style={{ width: "9rem" }} className="my-3" /></span>
                                </div>
                                <div className="side-menu-list">
                                    <ul className="nav nav-pills flex-column mb-auto" id="side-menu">
                                        <NavLink to={ROUTES.LAB.HOME + 'orders'} onClick={() => { setIsActive('order'); }} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "order" ? <img src={order} className="me-2 dashicon1" /> : <img src={orderAct} className="me-2 dashicon1" />}
                                            </div>
                                            <div className="flex-shrink-1">Order</div>
                                        </NavLink>
                                        <NavLink to={ROUTES.LAB.HOME + 'processing'} onClick={() => setIsActive('processing')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "processing" ? <img src={processing} className="me-2 dashicon1" /> : <img src={processingAct} className="me-2 dashicon1" />}
                                            </div>
                                            <div className="flex-shrink-1">Processing</div>
                                        </NavLink>
                                        <NavLink to={ROUTES.LAB.HOME + 'history'} onClick={() => setIsActive('history')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "history" ? <img src={historyIcon} className="me-2 dashicon1" /> : <img src={historyAct} className="me-2 dashicon1" />}
                                            </div>
                                            <div className="flex-shrink-1">History</div>
                                        </NavLink>
                                        <NavLink to={ROUTES.LAB.HOME + 'Upload'} onClick={() => setIsActive('upload')} className={clsx('nav-link d-flex side-menu-item rounded-0')} style={{ padding: '1rem 1.25rem' }}>
                                            <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                                                {isActive === "upload" ? <img src={uploadImg} className="me-2" style={{ width: "1rem" }} /> : <img src={uploadImg} className="me-2" style={{ width: "1rem" }} />}
                                            </div>
                                            <div className="flex-shrink-1">Upload</div>
                                        </NavLink>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='col-md-9'>
                        <Stack direction="row" spacing={5} className="align-items-center justify-content-evenly bg-white py-3 px-5">
                            <AppHeader loginName={user?.name} />
                        </Stack>

                    </div>
                </div>
                <div className='p-4 bg-secondary'>
                    {children}
                </div>

            </main>
        </div>
    </>);
}

export default AppLayout;