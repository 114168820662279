import React, {MutableRefObject, useRef} from 'react';
import clsx                              from "clsx";
import pageStyle                         from "../../pages/page.module.scss";

interface props {
    imgIcon?: any;
    imgIconSelected?: any;
    menuName?: string;
    active?: boolean;
    onClick?(): void
}

function TabMenuItem({imgIcon, imgIconSelected, menuName, active = false, onClick}: props) {
    const refImg = useRef() as MutableRefObject<HTMLImageElement>
    imgIcon      = active ? imgIconSelected : imgIcon;

    return (<>
        <div style={{width:"1250px"}} className={clsx(pageStyle.tabItem, 'd-flex', 'align-items-center', active && pageStyle.tabItemActive)} onMouseOver={e => refImg.current.src = imgIconSelected}
             onMouseLeave={e => refImg.current.src = imgIcon} onClick={onClick}>
            <div>
                <img src={imgIcon} alt={""} ref={refImg} style={{width: 'auto', height: '20px',paddingTop:"1px"}}/>
                {/* <span className="d-block">{menuName}</span> */}
                <span style={{width: '100px', height: '50px',paddingLeft:"10px",paddingTop:"30px",fontSize:"14px",fontStyle:"-moz-initial"}}>{menuName}</span>
            </div>
        </div>
    </>);
}

export default TabMenuItem;