import React, {lazy, Suspense} from 'react';
import PharmacyThemeProvider   from "../../providers/PharmacyThemeProvider";
import {Redirect, Switch}      from "react-router-dom";
import PublicRoute             from "../../routes/PublicRoute";
import {ROUTES}                from "../../config/Constants";
import AuthLayout              from "./components/AuthLayout";
import AppLayout               from "./components/AppLayout";
import {useStateValue}         from "../../providers/StateProvider";
import PrivateRoute            from "../../routes/PrivateRoute";

const Login          = lazy(() => import('./auth/Login'));
const Register       = lazy(() => import('./auth/Register'));
const ServiceTime    = lazy(() => import('./onboarding/ServiceTime'));
const ContactDetails = lazy(() => import('./onboarding/ContactDetails'));
const Documents      = lazy(() => import('./onboarding/Documents'));
const Confirmation   = lazy(() => import('./onboarding/Confirmation'));
const TabOrders      = lazy(() => import('./components/TabOrders'));
const TabProcessing  = lazy(() => import('./components/TabProcessing'));
const TabHistory     = lazy(() => import('./components/TabHistory'));
const TabPayments    = lazy(() => import('./components/TabPayments'));
const Processingstatus =lazy(() => import('./components/Processingstatus')); 
const Historypayment   =lazy(() => import('./components/Historypayment'));
const TabProcessingStatusPopup    = lazy(() => import('./components/TabProcessingStatuspopup'));
function Index() {
    const [{user}]: any = useStateValue();
    return (<div className="theme-pharmacy">
        <PharmacyThemeProvider>
            <SetLayout component={user ? AppLayout : AuthLayout} user={user}/>
        </PharmacyThemeProvider>
    </div>);
}

function SetLayout({component: Component, user}: any) {
    return (
        <Component>
            <Suspense fallback={null}>
                <Switch>
                    <PublicRoute path={ROUTES.PHARMACY.LOGIN} component={Login}/>
                    <PublicRoute path={ROUTES.PHARMACY.REGISTER} component={Register}/>
                    <PublicRoute path={ROUTES.PHARMACY.ONBOARD_SERVICE_TIME} component={ServiceTime}/>
                    <PublicRoute path={ROUTES.PHARMACY.ONBOARD_CONTACT_DETAILS} component={ContactDetails}/>
                    <PublicRoute path={ROUTES.PHARMACY.ONBOARD_DOCUMENT} component={Documents}/>
                    <PublicRoute path={ROUTES.PHARMACY.ONBOARD_CONFIRM} component={Confirmation}/>
                    <PrivateRoute path={ROUTES.PHARMACY.ORDERS} component={TabOrders}/>
                    <PrivateRoute path={ROUTES.PHARMACY.PROCESSING} component={TabProcessing}/>
                    <PrivateRoute path={ROUTES.PHARMACY.PAYMENT} component={TabPayments}/>
                    <PrivateRoute path={ROUTES.PHARMACY.HISTORY} component={TabHistory}/>
                    <Redirect from={ROUTES.PHARMACY.HOME} to={user ? ROUTES.PHARMACY.ORDERS : ROUTES.PHARMACY.LOGIN} exact/>
                    <PrivateRoute path={ROUTES.PHARMACY.PROCESSINGSTATUS} component={Processingstatus}/>
                    <PrivateRoute path={ROUTES.PHARMACY.HISTORYPAYMENT} component={Historypayment}/>
                    <PrivateRoute path={ROUTES.PHARMACY.TABPROCESSINGSTATUSPOPUP} component={TabProcessingStatusPopup}/>  
                </Switch>
            </Suspense>
        </Component>
    );
}

export default Index;