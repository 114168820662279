import React, {lazy, Suspense} from 'react';
import LabThemeProvider        from "../../providers/LabThemeProvider";
import {Redirect, Switch}      from "react-router-dom";
import PublicRoute             from "../../routes/PublicRoute";
import {ROUTES}                from "../../config/Constants";
import AuthLayout              from "./components/AuthLayout";
import AppLayout               from "./components/AppLayout";
import {useStateValue}         from "../../providers/StateProvider";
import PrivateRoute            from "../../routes/PrivateRoute";

const Login          = lazy(() => import('./auth/Login'));
const Register       = lazy(() => import('./auth/Register'));
const ServiceTime    = lazy(() => import('./onboarding/ServiceTime'));
const ContactDetails = lazy(() => import('./onboarding/ContactDetails'));
const Documents      = lazy(() => import('./onboarding/Documents'));
const Confirmation   = lazy(() => import('./onboarding/Confirmation'));
const TabOrders      = lazy(() => import('./components/TabOrders'));
const TabProcessing  = lazy(() => import('./components/TabProcessing'));
const TabHistory     = lazy(() => import('./components/TabHistory'));
const Processingstatus     = lazy(() => import('./components/Processingstatus'));
const UploadDocument =lazy(()=>import('./components/TabUploadDocument')) 


function Index() {
    const [{user}]: any = useStateValue();
    return (<div className="theme-lab">
        <LabThemeProvider>
            <SetLayout component={user ? AppLayout : AuthLayout} user={user}/>
        </LabThemeProvider>
    </div>);
}

function SetLayout({component: Component, user}: any) {
    return (<Component>
        <Suspense fallback={null}>
            <Switch>
                <PublicRoute path={ROUTES.LAB.LOGIN} component={Login}/>
                <PublicRoute path={ROUTES.LAB.REGISTER} component={Register}/>
                <PublicRoute path={ROUTES.LAB.ONBOARD_SERVICE_TIME} component={ServiceTime}/>
                <PublicRoute path={ROUTES.LAB.ONBOARD_CONTACT_DETAILS} component={ContactDetails}/>
                <PublicRoute path={ROUTES.LAB.ONBOARD_DOCUMENT} component={Documents}/>
                <PublicRoute path={ROUTES.LAB.ONBOARD_CONFIRM} component={Confirmation}/>
                <PrivateRoute path={ROUTES.LAB.ORDERS} component={TabOrders}/>
                <PrivateRoute path={ROUTES.LAB.PROCESSING} component={TabProcessing}/>
                <PrivateRoute path={ROUTES.LAB.HISTORY} component={TabHistory}/>
                <Redirect from={ROUTES.LAB.HOME} to={user ? ROUTES.LAB.ORDERS : ROUTES.LAB.LOGIN} exact/>
                <PrivateRoute path={ROUTES.LAB.Processingstatus} component={Processingstatus}/>
                <PrivateRoute path={ROUTES.LAB.UPLOADDOCUMENT} component={UploadDocument}/>
            </Switch>
        </Suspense>
    </Component>);
}

export default Index;