import React                        from 'react';
import ReactDOM                     from 'react-dom';
import {StateProvider}              from "./providers/StateProvider";
import StateService, {initialState} from './services/StateService';
import RouteInit                    from "./routes/RouteInit";
//import reportWebVitals from './reportWebVitals';

import './assets/scss/app.scss';

ReactDOM.render(
    <React.StrictMode>
        <StateProvider initialState={initialState} reducer={StateService}>
            <RouteInit/>
        </StateProvider>
    </React.StrictMode>,
    document.getElementById('rootContainer')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
