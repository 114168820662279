import React, {lazy, Suspense} from 'react';
import {useStateValue}         from "../../providers/StateProvider";
import {Redirect}              from "react-router-dom";
import {ROUTES}                from "../../config/Constants";

const HomeMain = lazy(() => import('./components/HomeMain'));

function Index() {
    const [{user}]: any = useStateValue();

    if (user?.type === 'Hospital') {
        return <Redirect to={ROUTES.HOSPITAL.REFERRAL}/>
    }

    if (user?.type === 'Lab') {
        return <Redirect to={ROUTES.LAB.ORDERS}/>
    }

    if (user?.type === 'Pharmacy') {
        return <Redirect to={ROUTES.PHARMACY.ORDERS}/>
    }

    if (user?.type === 'Agent') {
        return <Redirect to={ROUTES.AGENT.SUBSCRIPTIONS}/>
    }

    if (user?.type === 'SubAgent') {
        return <Redirect to={ROUTES.AGENT.SUBSCRIPTIONS}/>
    }

    return (<>
        <Suspense fallback={null}>
            <HomeMain/>
        </Suspense>
    </>);
}

export default Index;