import React, { useEffect } from 'react';
//import imgLogo         from "../../assets/images/logo.png";
import imgLogo from "../../../src/assets/images/logo-with-text.png";

import { Logout as IconLogout, PersonOutlineOutlined as IconPersonOutlineOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config/Constants";

import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase } from '@mui/material';
import profile from '../../assets/images/telemedicine/profile.png';
import Logout from '../../assets/images/telemedicine/logout.png';

interface props {

    loginName?: string;
    loginid?: string;
    logincode?: string;
    chkMainAgent?: boolean;
    subagentcode?: string;
    agenttype?: string;
}
function AppHeader(props: props) {

    const [logincode, setlogincode] = React.useState<any>();
    debugger;
    let subcode = props.agenttype === "SubAgent" ? props.subagentcode : props.logincode;
    console.log("subcode-" + subcode);
    useEffect(() => {
        debugger;
        if (props.logincode == "undefined") {
            const _token: string | null = localStorage.getItem('_su_token') as string;
            const user = JSON.parse(atob(_token));
            debugger;
            const response = {
                id: user?.agentcode
            };
            setlogincode(response.id);
        }
        else {
            setlogincode(props.logincode?.toString());
        }

    }, [])

    debugger;
    return (<>
        <span className='rounded--2 bg-secondary py-1'>
            <IconButton type="submit" className='ps-3' aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ flex: 1 }}
                placeholder="Find Something here"
                inputProps={{ 'aria-label': 'search' }}
            />
        </span>
        <span className='text-primary fw-bold'>{props.loginName}</span>

        <span><img src={profile} className="dashicon" /></span>

        <Link to={ROUTES.LOGOUT} className="nav-link text-dark">
            <div className='bolder' role="button">
                <div className='text-center'>
                    <img src={Logout} style={{ width: "1rem" }} />
                </div>
                <div>Logout</div>
            </div>
        </Link>
        {/* <div className="container-fluid d-flex align-items-center py-3 px-4" style={{ width: "1250px" }}>
            <div className="d-flex align-items-center">
                <img src={imgLogo} alt={""} style={{ width: '80px' }} />
            </div>
            <div className="ms-auto">
                <ul className="nav nav-pills">
                    <li className="nav-item d-flex align-items-center">
                        <label className="nav-link">{props.loginName}</label>
                        <label className="nav-link">{subcode}</label>

                    </li>
                    <li className="nav-item d-flex align-items-center">
                        <div className="nav-link">
                            <label className="text-dark border p-2 bg-white">
                                <IconPersonOutlineOutlined />
                            </label>
                        </div>
                    </li>
                    <li className="nav-item d-flex align-items-center">
                        <Link to={ROUTES.LOGOUT} className="nav-link text-dark">
                            <IconLogout className="d-block mx-auto" />
                            Logout
                        </Link>
                    </li>
                </ul>
            </div>
        </div> */}
    </>);
}

export default AppHeader;