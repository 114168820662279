const baseRoute     = '/';
//const baseApi       = 'htts://localhost:8080/';
const baseApi       = 'https://telemedicineapi.sysmedac.com/';
//const baseApi='https://telemedicineapi.sysmedac.com/';
const hospitalRoute = baseRoute + 'hospital/';
const agentRoute    = baseRoute + 'agent/';
const labRoute      = baseRoute + 'lab/';
const pharmacyRoute = baseRoute + 'pharmacy/';
const subagent      = baseRoute + 'agent/subagent/';

export const ROUTES = {
    HOME    : baseRoute,
    LOGOUT  : baseRoute + 'logout',
    HOSPITAL: {
        HOME                   : hospitalRoute,
        LOGIN                  : hospitalRoute + 'login',
        REGISTER               : hospitalRoute + 'register',
        ONBOARD_SERVICE_TIME   : hospitalRoute + 'service-time',
        ONBOARD_CONTACT_DETAILS: hospitalRoute + 'contact-details',
        ONBOARD_DOCUMENT       : hospitalRoute + 'document',
        ONBOARD_CONFIRM        : hospitalRoute + 'confirmation',
        REFERRAL               : hospitalRoute + 'referral',
        PROCESSING             : hospitalRoute + 'processing',
        HISTORY                : hospitalRoute + 'history',
        HistoryArrive          : hospitalRoute + 'HistoryArrive',
        Historynoshow          : hospitalRoute + 'Historynoshow',
        Historyall             : hospitalRoute + 'Historyall',
        REFERRALARRIVE         : hospitalRoute +  'ReferralArrive',
        REFERRALONSHOW         : hospitalRoute +  'Referralonshow', 
        REFERRALALL            : hospitalRoute +  'Referralall',
        HOSPITALUPDATEREFERALLSTATUS :baseApi+'hospitalReferral/updatehospitalreferralstatus',
        HOSPITALUPDATEREFERALLBILL :baseApi+'hospitalReferral/updatehospitalreferralbill',
        UPLOADDOCUMENT         : hospitalRoute +'UPLOADDOCUMENT',    
    },
    AGENT   : {
        HOME                   : agentRoute,
        LOGIN                  : agentRoute + 'login',
        REGISTER               : agentRoute + 'register',
        ONBOARD_CONTACT_DETAILS: agentRoute + 'contact-details',
        ONBOARD_DOCUMENT       : agentRoute + 'document',
        ONBOARD_CONFIRM        : agentRoute + 'confirmation',
        SUBSCRIPTIONS          : agentRoute + 'subscriptions',
        MY_TEAM                : agentRoute + 'my-team',
        PAYMENT                : agentRoute + 'payments',
        HISTORY                : agentRoute + 'history',
        PAYMENTHISTORY         : agentRoute + 'PaymentHistory',
        AGENT                  :agentRoute  + 'Agent',
        Subscriptioncancel                  :agentRoute  + 'Subscriptioncancel',
        Subscriptionexpaired                  :agentRoute  + 'Subscriptionexpaired',
        Subscriptionrenewal                  :agentRoute  + 'Subscriptionrenewal', 
    },
    SUBAGENT    :{
        HOME                   : subagent,
        ONBOARD_CONTACT_DETAILS: subagent + 'contact-details',
        ONBOARD_DOCUMENT       : subagent + 'document',
        ONBOARD_CONFIRM        : subagent + 'confirmation',
    },
    LAB     : {
        HOME                   : labRoute,
        LOGIN                  : labRoute + 'login',
        REGISTER               : labRoute + 'register',
        ONBOARD_SERVICE_TIME   : labRoute + 'service-time',
        ONBOARD_CONTACT_DETAILS: labRoute + 'contact-details',
        ONBOARD_DOCUMENT       : labRoute + 'document',
        ONBOARD_CONFIRM        : labRoute + 'confirmation',
        ORDERS                 : labRoute + 'orders',
        PROCESSING             : labRoute + 'processing',
        HISTORY                : labRoute + 'history',
        Processingstatus       : labRoute +'Processingstatus',
        UPLOADDOCUMENT         : labRoute +'UPLOADDOCUMENT',
        
    },
    PHARMACY: {
        HOME                   : pharmacyRoute,
        LOGIN                  : pharmacyRoute + 'login',
        REGISTER               : pharmacyRoute + 'register',
        ONBOARD_SERVICE_TIME   : pharmacyRoute + 'service-time',
        ONBOARD_CONTACT_DETAILS: pharmacyRoute + 'contact-details',
        ONBOARD_DOCUMENT       : pharmacyRoute + 'document',
        ONBOARD_CONFIRM        : pharmacyRoute + 'confirmation',
        ORDERS                 : pharmacyRoute + 'orders',
        PROCESSING             : pharmacyRoute + 'processing',
        PHARMACYSTATUSPOPUP    :pharmacyRoute + 'TabProcessingStatuspopup',
        PAYMENT                : pharmacyRoute + 'payments',
        HISTORY                : pharmacyRoute + 'history',
        PROCESSINGSTATUS       : pharmacyRoute + 'Processingstatus',
        HISTORYPAYMENT         : pharmacyRoute + 'Historypayment',
        PHARMACYORDER          : baseApi + 'Orders/pharmacyOrders',
        PHARMACYGETCOUNT       : baseApi + 'Orders/getCount',
        PHARMACYGETPAYMENT     : baseApi + 'Orders/getPayment',
        PHARMACYGETORDERHISTORY: baseApi + 'Orders/getOrderHistory',
        PHARMACYGETPAYMENTHISTORY :baseApi + 'Orders/getPaymentHistory',
        TABPROCESSINGSTATUSPOPUP :pharmacyRoute +'TabProcessingStatuspopup',   
        // PHARMACYORDEREXPORTPDF : baseApi  +  'Pharmacy/HistoryorderExportToPDF',
        // PHARMACYPAYMENTEXPORTPDF : baseApi  +  'Pharmacy/HistorypaymentExportToPDF',
        // PHARMACYORDEREXPORTEXCEL : baseApi  +  'Pharmacy/exportHistoryorderToExcel',
        // PHARMACYPAYMENTEXPORTEXCEL : baseApi  +  'Pharmacy/exportHistorypaymentToExcel',
        PHARMACYALLORDERS         : baseApi  +'Orders/AllpharmacyOrders',
        PHARMACYHISTORYORDERPDF         : baseApi  +'Pharmacyorder/HistoryorderExportToPDF',
        PHARMACYHISTORYORDEEXCEL         : baseApi  +'Pharmacyorder/exportHistoryorderToExcel',
        PHARMACYHISTORYPAYMENTPDF         : baseApi  +'Pharmacyorder/HistorypaymentExportToPDF',
        PHARMACYHISTORYPAYMENTEXCEL         : baseApi  +'Pharmacyorder/exportHistorypaymentToExcel',
        PHARMACYMEDICINELIST                :baseApi +'Pharmacyorder/getPharmacyMedicinelist',
        PHARMACYUPDATEORDERPROCESS          :baseApi +'Pharmacyorder/updatepharmacyorderprocess',
        PHARMACYGETWALLETAMOUNT             :baseApi+'Pharmacyorder/getWalletamount',
        PHARMACYINSERTORDERSTATUS           :baseApi+'Pharmacyorder/insertpharmacyorderstatus',
        PHARMACYUPDATEORDERSTATUS           :baseApi +'Pharmacyorder/updatepharmacyorderstatus',
        PHARMACYPAYMENTPDF                  :baseApi+'Pharmacyorder/PharmacypaymentExportToPDF', 
        PHARMACYPAYMENTEXCEL                :baseApi+'Pharmacyorder/PharmacypaymentExportToExcel',
        PHARMACYORDERPDF                    :baseApi+'Pharmacyorder/PharmacyorderExportToPDF',
        PHARMACYORDEREXCEL                  :baseApi+'Pharmacyorder/PharmacyorderExportToExcel',
        
    },
    API     : {
        OTP_SEND  : baseApi + 'mailAndSMS/getSmsOTP/',
        OTP_VERIFY: baseApi + 'mailAndSMS/verifySmsOTP/',
        HOSPITAL  : {
            REGISTER       : baseApi + 'HospitalPortal/saveHospital',
            SERVICE_TIME   : baseApi + 'HospitalPortal/updateServiceTime',
            CONTACT_DETAILS: baseApi + 'HospitalPortal/updateHospitalDetails',
            DOCUMENT       : baseApi + 'HospitalPortal/uploadFile',
            LOGIN_DETAILS  : baseApi + 'HospitalPortal/getLoginDetails',
            REFERRAL       : baseApi + 'HospitalPortal/getHospitalReferrals' ,
            UPDATE_STATUS  : baseApi + 'HospitalPortal/updateHospitalStatus'
                                       
        },
        AGENT     : {
            REGISTER       : baseApi + 'AgentPortal/saveAgent',
            CONTACT_DETAILS: baseApi + 'AgentPortal/updateAgentdetails',
            SERVICE_TIME   : baseApi + 'AgentPortal/updateServiceTime',
            DOCUMENT       : baseApi + 'AgentPortal/uploadFile',
            LOGIN_DETAILS  : baseApi + 'AgentPortal/getLoginDetails',
            UPDATE_STATUS  : baseApi + 'AgentPortal/updateAgentStatus',
            EXPORTAGENTSUBSCRIPTIONEXCEL :baseApi+'AgentPortal/AgentsubscriptionExportToExcel',
            EXPORTAGENTSUBSCRIPTIONPDF :baseApi+'AgentPortal/AgentsubscriptionExportToPDF',
            EXPORTAGENTPAYMENTEXCEL :baseApi+'AgentPortal/AgentPaymentExportToExcel',
            EXPORTAGENTPAYMENTPDF :baseApi+'AgentPortal/AgentPaymentExportToPDF',
            SUBSCRIPTIONTYPEPDF   :baseApi +'AgentPortal/AgentsubscriptiontypeExportToPDF',
            SUBSCRIPTIONTYPEXCEL :baseApi +'AgentPortal/AgentsubscriptiontypeExportToExcel',
            MYTEAMPAYMENTPDF     :baseApi+'AgentPortal/MyTeamAgentPaymentExportToPDF',
            MYTEAMPAYMENTEXCEL   :baseApi+'AgentPortal/MyTeamAgentPaymentExportToExcel', 
            SUBAGENTLISTPDF      :baseApi+'SubAgentPortal/SubAgentListExportToPDF',
            SUBAGENTLISTEXCEL      :baseApi+'SubAgentPortal/SubAgentListExportToExcel',
            

        },
        SUB_AGENT : {
            REGISTER       : baseApi + 'SubAgentPortal/saveSubAgent',
            CONTACT_DETAILS: baseApi + 'SubAgentPortal/updateSubAgentDetails',
            DOCUMENT       : baseApi + 'SubAgentPortal/uploadFile',
            AGENT_LIST     : baseApi + 'SubAgentPortal/getSubAgentList',
            UPDATE_STATUS  : baseApi + 'SubAgentPortal/updateSubAgentStatus',
            GET_LOGIN_DETAILS:baseApi+'SubAgentPortal/getLoginDetailsForSubAgent'
        },
        LAB       : {
            REGISTER       : baseApi + 'LabPortal/saveLab',
            SERVICE_TIME   : baseApi + 'LabPortal/updateServiceTime',
            CONTACT_DETAILS: baseApi + 'LabPortal/updateLabDetails',
            DOCUMENT       : baseApi + 'LabPortal/uploadFile',
            LOGIN_DETAILS  : baseApi + 'LabPortal/getLoginDetails',
            UPDATE_STATUS  : baseApi + 'LabPortal/updateLabStatus',
            UPLOADBILL     : baseApi + 'PatientBill/uploadNewBillbyservice',
            SEARCHBILLFORUPLOAD:baseApi+ 'Bill/searchBillforupload',
            LABHISTORYEXCEL:baseApi+ 'Orders/exportHistorylabToExcel',
            LABHISTORYPDF:baseApi+ 'Orders/HistorylaborderExportToPDF',
            LABORDERPDF:baseApi+'Orders/laborderExportToPDF',
            LABORDEREXCEL:baseApi+'Orders/laborderExportToExcel',
           
        },
        PHARMACY  : {
            REGISTER       : baseApi + 'PharmacyPortal/savePharmacy',
            SERVICE_TIME   : baseApi + 'PharmacyPortal/updateServiceTime',
            CONTACT_DETAILS: baseApi + 'PharmacyPortal/updatePharmacyDetails',
            DOCUMENT       : baseApi + 'PharmacyPortal/uploadFile',
            LOGIN_DETAILS  : baseApi + 'PharmacyPortal/getLoginDetails',
            UPDATE_STATUS  : baseApi + 'PharmacyPortal/updatePharmacyStatus',
            GETALLMEDICINE : baseApi + 'Consultation/getAllMedicinesForDDL',
        }
    }
};