import React ,{useState,useEffect} from 'react';
import {ROUTES}        from "../../../config/Constants";
import { Link } from "react-router-dom";
import {useHistory}    from 'react-router-dom';

import ExportExcel from '../../../assets/images/ExportExcel.png';
import ExportPDF from "../../../assets/images/ExportPdf.png";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';


import TableSortLable from "@material-ui/core/TableSortLabel";
import { httpGet } from "../../../services/HttpService";

import { Redirect } from "react-router-dom";
import { Search } from '@mui/icons-material';
import { IconButton, InputBase, Paper as Paperseach } from "@mui/material";
import { Search as IconSearch } from "@mui/icons-material";
import pageStyle from "../../page.module.scss";

function Paymenthistory(){
    const history                 = useHistory();
    const opennewlink =()=>{
        history.push(ROUTES.AGENT.HISTORY);
 
    }  
    const ExportToPdfClick = () => {
        window.open(ROUTES.API.AGENT.EXPORTAGENTPAYMENTPDF+'?agentid='+agentid);
        
    }
    const ExportToExcelClick = () => {
        window.open('https://telemedicineapi.sysmedac.com/AgentPortal/AgentPaymentExportToExcel?agentid='+agentid)
       // window.open(ROUTES.API.AGENT.EXPORTAGENTPAYMENTEXCEL+'?agentid='+agentid);
         
    }

    const [agentdetails, setagentdetails] = useState([]);
    const [agentid,setagentid]=useState(); 
    useEffect(() => {
        const _token: string | null = localStorage.getItem('_su_token') as string;
        const user = JSON.parse(atob(_token));
        debugger;
        const response = {
            id: user?.type==="SubAgent"? user?.subagentcode:user?.agentcode
        };
        setagentid(response.id);
        bindGrid(response.id);
    }, [])

    const bindGrid = (agentid:any) => {
        debugger;
        //httpGet('https://telemedicineapi.sysmedac.com/AgentPortal/getPayment?agentid=' + agentid)
        httpGet('https://telemedicineapi.sysmedac.com/AgentPortal/getsugamahpayment?agentcode='+agentid)

            .then(response => {
                debugger;
                
                const vData: any = (response.data.result);
                setagentdetails(vData);
            })
            .catch(error => {
            });
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [columnorder,setcolumnorder]=useState("ASC");

    const sorting=(col: any)=>{
        if(columnorder==="ASC"){
            const sorted=[...agentdetails].sort((a,b)=>
            a[col]>b[col]?1:-1);
            setagentdetails(sorted);
            setcolumnorder("DSC");
        }
        if(columnorder==="DSC"){
            const sorted=[...agentdetails].sort((a,b)=>
            a[col]<b[col]?1:-1);
            setagentdetails(sorted);
            setcolumnorder("ASC");
        }
    }

    const[seachtext,setseachtext]=useState('')
    const [fixseachvalue,setfixseachvalue]=useState('');
    const findsearch =(e:any)=>{
        // debugger;
        setfixseachvalue(seachtext);
    }

    const setsearchvalue=(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
        setseachtext(e.target.value);
    }

    return(
    <>

<div className="col-md-4 d-flex justify-content-end order-0 order-md-1 mb-3 mb-md-0" style={{ float: "right",padding:"10px" }}>
                <Paperseach sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '25rem' }} className={pageStyle.tabSearch}>
                    <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search"  value={seachtext} onChange={(e) => setsearchvalue(e)}  />
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={(e)=>findsearch(e)} >
                        <IconSearch />
                    </IconButton>
                </Paperseach>
            </div>
    <div style={{paddingBottom:"25px"}}>
    <ul className="nav">
                <li className="nav-item border-2 border-primary">
                    <li className="nav-item border-bottom border-2 border-white" style={{float:"left"}}>
                        <Link to={ROUTES.AGENT.HISTORY} className="nav-link " style={{ fontWeight: "bold" }} >Subscription</Link>
                    </li>
                    <li className="nav-item border-bottom border-2 border-primary" style={{float:"right"}}>
                        <Link to={ROUTES.AGENT.PAYMENTHISTORY} className="nav-link" style={{ fontWeight: "bold" }}>Payment</Link>
                    </li>
                </li>
            </ul>
        </div>
        <Paper >
        <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell onClick={()=>sorting("srno")} className="border-primary" style={{ textAlign: 'center' }}>Sr.no</TableCell>
                            <TableCell onClick={()=>sorting("currentdate")} className="border-primary" style={{ textAlign: 'center' }}>Date</TableCell>
                            <TableCell onClick={()=>sorting("patientid")} className="border-primary" style={{ textAlign: 'center' }}>Member Code</TableCell>
                            <TableCell onClick={()=>sorting("plantype")} className="border-primary" style={{ textAlign: 'center' }}>Plan Type</TableCell>
                            <TableCell onClick={()=>sorting("incometype")} className="border-primary" style={{ textAlign: 'center' }}>Income Type</TableCell>
                            <TableCell onClick={()=>sorting("commission")} className="border-primary" style={{ textAlign: 'center' }}>Amount</TableCell>
                            <TableCell onClick={()=>sorting("payableamount")} className="border-primary" style={{ textAlign: 'center' }}>Amount Received</TableCell>
                            <TableCell onClick={()=>sorting("due")} className="border-primary" style={{ textAlign: 'center' }}>Due</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agentdetails.filter((val:any)=>{debugger;
                                if(fixseachvalue==null || fixseachvalue=="")
                                {return val}
                                else if( 
                                   val.currentdate==(fixseachvalue)||val.commission==(fixseachvalue)||
                                   val.payableamount==(fixseachvalue)||val.due==(fixseachvalue)||
                                   val.srno==(fixseachvalue)||val.patientid==(fixseachvalue)||
                                   val.plantype==(fixseachvalue)||val.incometype==(fixseachvalue))
                                    {
                                return val;  
                                }
                            }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: {
                            currentdate: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                            commission: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                            payableamount: boolean | React.ReactChild
                            | React.ReactFragment | React.ReactPortal | null | undefined;
                            due: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                            srno: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                            patientid: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                            plantype: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                            incometype: boolean | React.ReactChild | React.ReactFragment
                            | React.ReactPortal | null | undefined;
                        }) => (
                            <TableRow>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.srno}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.currentdate}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.patientid}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.plantype}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.incometype}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.commission}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.payableamount}</TableCell>
                                <TableCell style={{ paddingTop: '8px', paddingBottom: '8px', textAlign: 'center' }}>{row.due}</TableCell>
                            


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="row"  >
                <div className="col-md-6"></div>
                <div className="col-md-6" style={{ textAlign: 'right', paddingTop: '15px' }}>
                    <TablePagination style={{ float: "right" }}
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
                        colSpan={3}
                        count={agentdetails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                'roboto': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </div>
            </div>
        </Paper>
        <div className="row">
        <div className="col-md-11">
            
        </div>
                    <div className="col-md-1" style={{ textAlign: 'right', paddingTop: '15px' }}>
                        <img src={ExportExcel} alt="Sugamah Excel" style={{ height: '22px' }} onClick={ExportToExcelClick}></img>&nbsp;&nbsp;&nbsp;
                        <img src={ExportPDF} alt="Sugamah Pdf" style={{ height: '22px' }} onClick={ExportToPdfClick}></img>
                    </div>
                    </div>

    </>)
}
export default Paymenthistory