import React             from 'react';
import {useStateValue}   from "../providers/StateProvider";
import {Redirect, Route} from "react-router-dom";
import {ROUTES}          from "../config/Constants";

function PrivateRoute({component: Component, ...rest}: any) {
    const [{user}]: any = useStateValue();
    return (
        <Route {...rest} render={props => (
            user ?
            <Component {...props} />
                 : <Redirect to={ROUTES.HOME}/>
        )}/>
    );
}

export default PrivateRoute;