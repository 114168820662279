import React, {lazy, Suspense} from 'react';

import AuthLayout            from "./components/AuthLayout";
import {Redirect, Switch}    from "react-router-dom";
import PublicRoute           from "../../routes/PublicRoute";
import {ROUTES}              from "../../config/Constants";
import HospitalThemeProvider from "../../providers/HospitalThemeProvider";
import AppLayout             from "./components/AppLayout";
import {useStateValue}       from "../../providers/StateProvider";
import PrivateRoute          from "../../routes/PrivateRoute";

const Login          = lazy(() => import('./auth/Login'));
const Register       = lazy(() => import('./auth/Register'));
const ServiceTime    = lazy(() => import('./onboarding/ServiceTime'));
const ContactDetails = lazy(() => import('./onboarding/ContactDetails'));
const Documents      = lazy(() => import('./onboarding/Documents'));
const Confirmation   = lazy(() => import('./onboarding/Confirmation'));
const TabReferral    = lazy(() => import('./components/TabReferral'));
const TabProcessing  = lazy(() => import('./components/TabProcessing'));
const TabHistory     = lazy(() => import('./components/TabHistory'));
const HistoryArrive  = lazy(() => import('./components/HistoryArrive'));
const Historynoshow  = lazy(() => import('./components/Historynoshow'));
const Historyall     = lazy(() => import('./components/Historyall') ); 
const Referralarrive =lazy (() => import('./components/ReferralArrive'));
const Referralnoshow =lazy (() => import('./components/Referralnoshow'));
const Referralall =lazy (() => import('./components/Referralall'));
const UploadDocument =lazy(()=>import('./components/TabUploadDocument')) 
function Index() {
    const [{user}]: any = useStateValue();
    return (<div className="theme-hospital">
        <HospitalThemeProvider>
            <SetLayout component={user ? AppLayout : AuthLayout} user={user}/>
        </HospitalThemeProvider>
    </div>);
}

function SetLayout({component: Component, user}: any) {
    return (
        <Component>
            <Suspense fallback={null}>
                <Switch>
                    <PublicRoute path={ROUTES.HOSPITAL.LOGIN} component={Login}/>
                    <PublicRoute path={ROUTES.HOSPITAL.REGISTER} component={Register}/>
                    <PublicRoute path={ROUTES.HOSPITAL.ONBOARD_SERVICE_TIME} component={ServiceTime}/>
                    <PublicRoute path={ROUTES.HOSPITAL.ONBOARD_CONTACT_DETAILS} component={ContactDetails}/>
                    <PublicRoute path={ROUTES.HOSPITAL.ONBOARD_DOCUMENT} component={Documents}/>
                    <PublicRoute path={ROUTES.HOSPITAL.ONBOARD_CONFIRM} component={Confirmation}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.REFERRAL} component={TabReferral}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.PROCESSING} component={TabProcessing}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.HISTORY} component={TabHistory}/>
                    <Redirect from={ROUTES.HOSPITAL.HOME} to={user ? ROUTES.HOSPITAL.REFERRAL : ROUTES.HOSPITAL.LOGIN} exact/>
                    <PrivateRoute path={ROUTES.HOSPITAL.HistoryArrive} component={HistoryArrive}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.Historynoshow} component={Historynoshow}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.Historyall} component={Historyall}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.REFERRALARRIVE} component={Referralarrive}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.REFERRALONSHOW} component={Referralnoshow}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.REFERRALALL} component={Referralall}/>
                    <PrivateRoute path={ROUTES.HOSPITAL.UPLOADDOCUMENT} component={UploadDocument}/>
                </Switch>
            </Suspense>
        </Component>
    );
}

export default Index;