import React, {useEffect} from 'react';
import {ROUTES}           from "../../config/Constants";
import {useHistory}       from "react-router-dom";
import {useStateValue}    from "../../providers/StateProvider";

function Logout() {
    const history                 = useHistory();
    const [{user}, dispatch]: any = useStateValue();

    const logout = () => {
        localStorage.clear();
        dispatch({
                     type: 'SET_USER',
                     user: null,
                 });

        history.push(ROUTES.HOME);
    };

    useEffect(logout, [history, dispatch]);

    return (<>
        {user.name} logging out...
    </>);
}

export default Logout;