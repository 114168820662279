import React                        from 'react';
import {createTheme, ThemeProvider} from "@mui/material";

interface props {
    children?: React.ReactNode;
}

let theme = createTheme({
                            palette   : {
                                primary: {
                                    main: '#331C8D',
                                    contrastText: '#ffffff'
                                },
                                secondary: {
                                    main: '#3C3573',
                                    contrastText: '#ffffff'
                                },
                                success: {
                                    main: '#0BF239',
                                    contrastText: '#ffffff'
                                },
                                warning: {
                                    main: '#FFEE03',
                                    contrastText: '#ffffff'
                                },
                            }
                        });

function AgentThemeProvider({children}: props) {
    return (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    );
}

export default AgentThemeProvider;